import React from "react";
import LogInFragment from "../Fragments/LogInFragment";

const Login = () => {
  return (
    <div>
      <LogInFragment />
    </div>
  );
};

export default Login;
