import React, { useContext } from "react";
import { Box, Stepper } from "@material-ui/core";
import Firststep from "../../Component.js/Form3Components/Firststep";
import Secoundstep from "../../Component.js/Form3Components/Secoundstep";
import Thirdstep from "../../Component.js/Form3Components/Thirdstep";
import Fourthstep from "../../Component.js/Form3Components/Fourthstep";
import Fifthstep from "../../Component.js/Form3Components/Fifthstep";
import Sixthstep from "../../Component.js/Form3Components/Sixthstep";
import Seventhstep2 from "../../Component.js/Form3Components/SeventhStep";
import Eightstep from "../../Component.js/Form3Components/EighthStep";
import Ninethstep2 from "../../Component.js/Form3Components/NinethStep";
import Tenthstep2 from "../../Component.js/Form3Components/TenthStep";
import Eleventhstep2 from "../../Component.js/Form3Components/EleventhStep";
import Twelthstep2 from "../../Component.js/Form3Components/TwelthStep";
import Thirteenthstep2 from "../../Component.js/Form3Components/ThirteenthStep";
import { mainContext } from "../../Contexts/MainContext";
import Fourteenthstep2 from "../../Component.js/Form3Components/FourteenthStep";
import FifteenthStep from "../../Component.js/Form3Components/FifteenthStep";
import SixteenthStep from "../../Component.js/Form3Components/SixteenthStep";
import SeventeenthStep from "../../Component.js/Form3Components/SeventeenthStep";
import EighteenthStep from "../../Component.js/Form3Components/EighteenthStep";

export default function CMultiStepForm(props) {
  const { currentStep } = useContext(mainContext);

  function showStep(step) {
    console.log(step);
    switch (step) {
      case 1:
        return (
          <Firststep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 2:
        return (
          <Secoundstep
            dialogData={{ ...props.dialogData, FileType: "CRF" }}
            document={"document2"}
            setFile={"setFile2"}
          />
        );
      case 3:
        return (
          <Thirdstep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 4:
        return (
          <Fourthstep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 5:
        return (
          <Fifthstep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 6:
        return (
          <Sixthstep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 7:
        return (
          <Seventhstep2 dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 8:
        return (
          <Eightstep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 9:
        return (
          <Ninethstep2 dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 10:
        return (
          <Tenthstep2 dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 11:
        return (
          <Eleventhstep2
            dialogData={{ ...props.dialogData, FileType: "CRF" }}
          />
        );
      case 12:
        return (
          <Twelthstep2 dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
      case 13:
        return (
          <Thirteenthstep2
            dialogData={{ ...props.dialogData, FileType: "CRF" }}
          />
        );
      case 14:
        return (
          <Fourteenthstep2
            dialogData={{ ...props.dialogData, FileType: "IRF" }}
          />
        );
      case 15:
        return (
          <FifteenthStep
            dialogData={{ ...props.dialogData, FileType: "IRF" }}
          />
        );
      case 16:
        return (
          <SixteenthStep
            dialogData={{ ...props.dialogData, FileType: "IRF" }}
          />
        );
      case 17:
        return (
          <SeventeenthStep
            dialogData={{ ...props.dialogData, FileType: "IRF" }}
          />
        );
      case 18:
        return (
          <EighteenthStep
            dialogData={{ ...props.dialogData, FileType: "IRF" }}
          />
        );
      default:
        return (
          <Firststep dialogData={{ ...props.dialogData, FileType: "CRF" }} />
        );
    }
  }

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <Box p={1} width="100%" bgcolor="white">
          <header className="App-header">
            <div className="center-stepper">
              <Stepper
                style={{ width: "100%" }}
                alternativeLabel
                activeStep={currentStep - 1}
                orientation="horizontal"
              ></Stepper>
            </div>
            {showStep(currentStep)}
          </header>
        </Box>
      </Box>
    </div>
  );
}
