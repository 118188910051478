import { firestore } from "../../../Firebase";

export const loadIndividualUpload = (FormType, Form, onSuccess, onError) => {
  return (dispatch) => {
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .get()
      .then((querySnapshot) => {
        let IndividualUpload = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            IndividualUpload.push(doc.data());
          });
          dispatch({
            type: "LOAD_INDIVIDUALUPLOAD",
            payload: IndividualUpload,
          });
          onSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
        onError();
      });
  };
};

export const addIndividualUpload = (
  data,
  FormType,
  Form,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc(data.uid)
      .set(data)
      .then(function (doc) {
        dispatch({ type: "ADD_INDIVIDUALUPLOAD", payload: data });
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        onError();
      });
  };
};

export const addIndividualUpload1 = (
  data,
  FormType,
  Form,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("DealingDocuments")
      .doc(data.uid)
      .set(data)
      .then(function (doc) {
        dispatch({ type: "ADD_INDIVIDUALUPLOAD", payload: data });
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        onError();
      });
  };
};

export const updateIndividualUpload = (
  data,
  FormType,
  Form,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc(data.id)
      .update(data)
      .then(function (doc) {
        dispatch({ type: "UPDATE_INDIVIDUALUPLOAD", payload: data });
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        onError();
      });
  };
};
