import { Grid, Typography } from "@material-ui/core";
import React from "react";

const Data = (props) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
          <Typography variant="caption">{props.label}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "left", color: "blue" }}>
          <Typography variant="caption">{props.Data}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Data;
