import React, { Component, forwardRef } from "react";
import { Container, Grid } from "@material-ui/core";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { connect } from "react-redux";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";
import { updateRegistrationStep1 } from "../../Store/Action/RegistrationStep1Action";
import VIDoc1 from "../../Forms/Accounts/VIDoc1";
import { loadRegistrationStep3 } from "../../Store/Action/RegistrationStep3Action";
import { mainContext } from "../../Contexts/MainContext";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class CompleteRegistrationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: "IsVerified",
          field: "validation",
          type: "boolean",
        },
        { title: "Customer's Name", field: "customerName", editable: false },
        {
          title: "Father's Name / Husband Name",
          field: "fatherName",
          editable: false,
        },
        {
          title: "Address Line1",
          field: "addressLine1",
          editable: false,
        },
        {
          title: "Address Line2",
          field: "addressLine2",
          editable: false,
        },
        {
          title: "Date Of Birth",
          field: "dateOfBirth",
          editable: false,
        },
        {
          title: "Mobile Number",
          field: "mobileNumber",
          editable: false,
        },
        {
          title: "PAN Number",
          field: "panNumber",
          editable: false,
        },
        {
          title: "Aadhar Number",
          field: "aadharNumber",
          editable: false,
        },
        {
          title: "Registration Date",
          field: "dateOfRegistration",
          editable: false,
        },
        { title: "Sale Date", field: "saleDate", editable: false },
        { title: "Others", field: "other", editable: false },
        { title: "Form Uploaded by", field: "FormUploadedby", editable: false },
        { title: "Engine Number", field: "EngineNumber", editable: false },
        { title: "Model Name", field: "ModelName", editable: false },
        { title: "Model Color", field: "ModelColor", editable: false },
        {
          title: "Commision Number",
          field: "CommisionNumber",
          editable: false,
        },
        {
          title: "Vahicle Detail Other",
          field: "vahicleOther",
          editable: false,
        },
      ],
      open: false,
      dialogData: [],
      FormType: "IRF",
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  componentDidMount() {
    this.props.loadRegistrationStep3(
      this.state.FormType,
      () => {},
      () => {
        //error
      }
    );
  }

  render() {
    console.log(this.props.FormType);
    const {
      setStep,
      setDocument1,
      setDocument2,
      setDocument3,
      setDocument4,
      setDocument5,
      setDocument6,
      setDocument7,
      setDocument8,
      setDocument9,
      setDocument10,
      setDocument11,
      setDocument12,
      setDocument13,
      setDocument14,
      setDocument15,
      setDocument16,
      setDocument17,
      setDocument18,
    } = this.context;
    console.log(this.props.RegistrationStep1);
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={this.Transition}
          style={{ backgroundColor: "#f7f7f7" }}
        >
          <AppBar style={{ position: "relative", backgroundColor: "#3C4B64" }}>
            <Toolbar>
              <Grid container>
                <Grid item xs={4} style={{ textAlign: "left" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.handleClose();
                      setStep(1);
                      setDocument1("");
                      setDocument2("");
                      setDocument3("");
                      setDocument4("");
                      setDocument5("");
                      setDocument6("");
                      setDocument7("");
                      setDocument8("");
                      setDocument9("");
                      setDocument10("");
                      setDocument11("");
                      setDocument12("");
                      setDocument13("");
                      setDocument14("");
                      setDocument15("");
                      setDocument16("");
                      setDocument17("");
                      setDocument18("");
                    }}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", paddingTop: 10 }}
                >
                  <Typography variant="h6">Upload Document</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}></Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <VIDoc1 dialogData={this.state.dialogData} />
        </Dialog>
        <div style={{ width: "95%" }}>
          <Container maxWidth="lg" fixed>
            <MaterialTable
              icons={tableIcons}
              title="Individual Complete Form Registration Details"
              columns={this.state.columns}
              data={this.props.RegistrationStep1}
              options={{
                exportButton: true,
                search: true,
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    if (newData === oldData) {
                      resolve();
                    } else {
                      this.props.updateRegistrationStep1(
                        newData,
                        this.state.FormType,
                        () => {
                          resolve();
                          this.props.loadRegistrationStep2(
                            this.state.FormType,
                            () => {},
                            () => {
                              //error
                            }
                          );
                        },
                        (error) => resolve()
                      );
                    }
                  }),
              }}
              actions={[
                {
                  icon: () => <AddPhotoAlternateIcon />,

                  tooltip: "Add Document",
                  onClick: (event, rowData) => {
                    this.handleClickOpen();
                    this.setState({ dialogData: { ...rowData } });
                    console.log(rowData);
                  },
                },
              ]}
            />
          </Container>
        </div>
      </div>
    );
  }
}

CompleteRegistrationTable.contextType = mainContext;

const mapStateToProps = (state) => {
  return {
    RegistrationStep1: state.RegistrationStep1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRegistrationStep3: (FormType, onSuccess, onError) =>
      dispatch(loadRegistrationStep3(FormType, onSuccess, onError)),
    updateRegistrationStep1: (Data, FormType, onSuccess, onError) =>
      dispatch(updateRegistrationStep1(Data, FormType, onSuccess, onError)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteRegistrationTable);
