import React, { useState } from "react";

import { Button } from "@material-ui/core";
import IPendingDocumentTable from "../../../Tables/Accounts/IPendingDocumentTable";
import CPendingDocumentTable from "../../../Tables/Accounts/CPendingDocumentTable";
import * as ROLES from "../../../UsersComponents/constants/roles";
import { compose } from "redux";
import { withAuthorization } from "../../../UsersComponents/Session";
import { withFirebase } from "../../../UsersComponents/Firebase";

const PFUDAccountsFragment = () => {
  const [FormType, setFormType] = useState(true);

  const Form = () => {
    if (FormType === true) {
      return <IPendingDocumentTable />;
    } else if (FormType === false) {
      return <CPendingDocumentTable />;
    }
  };

  console.log(FormType);

  const handleClickOpen = () => {
    setFormType(!FormType);
  };

  return (
    <div>
      <Button
        style={{ padding: 10, margin: 10, marginLeft: 30 }}
        color="primary"
        onClick={handleClickOpen}
      >
        Change Form Type
      </Button>
      {Form()}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ACCOUNT];
export default compose(
  withAuthorization(condition),
  withFirebase
)(PFUDAccountsFragment);
