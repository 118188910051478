import React from "react";
import { Grid, Divider, Box, Typography } from "@material-ui/core";
import Data from "../../Component.js/Form2Components/Data";
import IMultiStepForm from "./IMultiStepForm";

const VIDoc = (props) => {
  console.log("props", props);
  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ backgroundColor: "#f7f7f7", padding: 10 }}
    >
      <Box p={1} width="100%" bgcolor="white" style={{ padding: 50 }}>
        <Grid container style={{ backgroundColor: "white" }}>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Typography variant="h5">Registration Data</Typography>
            <Grid style={{ paddingTop: 10 }}>
              <Data label={"Registration Id"} Data={props.dialogData.uid} />
              <Data label={"Registration Type"} Data={props.dialogData.type} />
              <Data
                label={"Registration Date"}
                Data={props.dialogData.dateOfRegistration}
              />
              <Data label={"Sale Date"} Data={props.dialogData.saleDate} />
              <Data label={"props"} Data={props.dialogData.props} />
              <Data label={"District"} Data={props.dialogData.district} />
              <Data label={"Pincode"} Data={props.dialogData.pincode} />
              <Data label={"Other"} Data={props.dialogData.other} />
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Typography variant="h5">Coustomer Data</Typography>
            <Grid style={{ paddingTop: 10 }}>
              <Data
                label={"Customer Name"}
                Data={props.dialogData.customerName}
              />
              <Data label={"Father Name"} Data={props.dialogData.fatherName} />
              <Data
                label={"Date Of Birth"}
                Data={props.dialogData.dateOfBirth}
              />
              <Data
                label={"Mobile Number"}
                Data={props.dialogData.mobileNumber}
              />
              <Data label={"Pan Number"} Data={props.dialogData.panNumber} />
              <Data
                label={"Aadhar Number"}
                Data={props.dialogData.aadharNumber}
              />
              <Data label={"Address"} Data={props.dialogData.addressLine1} />
              <Data label={""} Data={props.dialogData.addressLine2} />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 30 }} />
        <Grid item xs={12} style={{ textAlign: "left", width: "100%" }}>
          <IMultiStepForm dialogData={props.dialogData} />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
      </Box>
    </Box>
  );
};

export default VIDoc;
