import React, { useContext } from "react";
import { Box, Stepper } from "@material-ui/core";
import Firststep from "../../Component.js/Form2Components/Firststep";
import Secoundstep from "../../Component.js/Form2Components/Secoundstep";
import Thirdstep from "../../Component.js/Form2Components/Thirdstep";
import Fourthstep from "../../Component.js/Form2Components/Fourthstep";
import Fifthstep from "../../Component.js/Form2Components/Fifthstep";
import Sixthstep from "../../Component.js/Form2Components/Sixthstep";
import Seventhstep2 from "../../Component.js/Form2Components/SeventhStep";
import Eightstep from "../../Component.js/Form2Components/EighthStep";
import Ninethstep2 from "../../Component.js/Form2Components/NinethStep";
import Tenthstep2 from "../../Component.js/Form2Components/TenthStep";
import Eleventhstep2 from "../../Component.js/Form2Components/EleventhStep";
import Twelthstep2 from "../../Component.js/Form2Components/TwelthStep";
import Thirteenthstep2 from "../../Component.js/Form2Components/ThirteenthStep";
import { mainContext } from "../../Contexts/MainContext";
import FourteenthStep from "../../Component.js/Form2Components/FourteenthStep";
import FifteenthStep from "../../Component.js/Form2Components/FifteenthStep";
import SixteenthStep from "../../Component.js/Form2Components/SixteenthStep";
import SeventeenthStep from "../../Component.js/Form2Components/SeventeenthStep";
import EighteenthStep from "../../Component.js/Form2Components/EighteenthStep";

export default function IMultiStepForm(props) {
  const { currentStep } = useContext(mainContext);

  function showStep(step) {
    console.log(step);
    switch (step) {
      case 1:
        return <Firststep dialogData={props.dialogData} />;
      case 2:
        return (
          <Secoundstep
            dialogData={props.dialogData}
            document={"document2"}
            setFile={"setFile2"}
          />
        );
      case 3:
        return <Thirdstep dialogData={props.dialogData} />;
      case 4:
        return <Fourthstep dialogData={props.dialogData} />;
      case 5:
        return <Fifthstep dialogData={props.dialogData} />;
      case 6:
        return <Sixthstep dialogData={props.dialogData} />;
      case 7:
        return <Seventhstep2 dialogData={props.dialogData} />;
      case 8:
        return <Eightstep dialogData={props.dialogData} />;
      case 9:
        return <Ninethstep2 dialogData={props.dialogData} />;
      case 10:
        return <Tenthstep2 dialogData={props.dialogData} />;
      case 11:
        return <Eleventhstep2 dialogData={props.dialogData} />;
      case 12:
        return <Twelthstep2 dialogData={props.dialogData} />;
      case 13:
        return <Thirteenthstep2 dialogData={props.dialogData} />;
      case 14:
        return <FourteenthStep dialogData={props.dialogData} />;
      case 15:
        return <FifteenthStep dialogData={props.dialogData} />;
      case 16:
        return <SixteenthStep dialogData={props.dialogData} />;
      case 17:
        return <SeventeenthStep dialogData={props.dialogData} />;
      case 18:
        return <EighteenthStep dialogData={props.dialogData} />;
      default:
        return <Firststep dialogData={props.dialogData} />;
    }
  }

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <Box p={1} width="100%" bgcolor="white">
          <header className="App-header">
            <div className="center-stepper">
              <Stepper
                style={{ width: "100%" }}
                alternativeLabel
                activeStep={currentStep - 1}
                orientation="horizontal"
              ></Stepper>
            </div>
            {showStep(currentStep)}
          </header>
        </Box>
      </Box>
    </div>
  );
}
