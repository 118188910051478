import React, { Component, forwardRef } from "react";
import { Container, Grid, Divider, Box } from "@material-ui/core";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { connect } from "react-redux";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";
import IMultiStepForm from "../../Forms/BackOffice/IMultiStepForm";
import { loadRegistrationStep1 } from "../../Store/Action/RegistrationStep1Action";
import Data from "../../Component.js/Form2Components/Data";
import { mainContext } from "../../Contexts/MainContext";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class IDUFTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: "Status", field: "Status" },
        { title: "Customer's Name", field: "customerName" },
        { title: "Registration Date", field: "dateOfRegistration" },
        { title: "Sale Date", field: "saleDate" },
        { title: "Comment", field: "comment", editable: false },
      ],
      open: false,
      dialogData: [],
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  componentDidMount() {
    this.props.loadRegistrationStep1(
      this.props.FormType,
      () => {},
      () => {
        //error
      }
    );
  }

  reload() {
    this.props.loadRegistrationStep1(
      this.props.FormType,
      () => {
        console.log("Success");
      },
      () => {
        //error
      }
    );
  }

  render() {
    console.log(this.context);
    const {
      setStep,
      setDocument1,
      setDocument2,
      setDocument3,
      setDocument4,
      setDocument5,
      setDocument6,
      setDocument7,
      setDocument8,
      setDocument9,
      setDocument10,
      setDocument11,
      setDocument12,
      setDocument13,
      setDocument14,
      setDocument15,
      setDocument16,
      setDocument17,
      setDocument18,
    } = this.context;
    const box = () => {
      if (this.props.RegistrationStep1) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            style={{ backgroundColor: "#f7f7f7", padding: 20 }}
          >
            <Box p={1} width="100%" bgcolor="white" style={{ padding: 50 }}>
              <Grid container style={{ backgroundColor: "white" }}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography variant="h5">Registration Data</Typography>
                  <Grid style={{ paddingTop: 10 }}>
                    <Data
                      label={"Registration Id"}
                      Data={this.state.dialogData.uid}
                    />
                    <Data
                      label={"Registration Type"}
                      Data={this.state.dialogData.type}
                    />
                    <Data
                      label={"Registration Date"}
                      Data={this.state.dialogData.dateOfRegistration}
                    />
                    <Data
                      label={"Sale Date"}
                      Data={this.state.dialogData.saleDate}
                    />
                    <Data label={"State"} Data={this.state.dialogData.state} />
                    <Data
                      label={"District"}
                      Data={this.state.dialogData.district}
                    />
                    <Data
                      label={"Pincode"}
                      Data={this.state.dialogData.pincode}
                    />
                    <Data label={"Other"} Data={this.state.dialogData.other} />
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography variant="h5">Coustomer Data</Typography>
                  <Grid style={{ paddingTop: 10 }}>
                    <Data
                      label={"Customer Name"}
                      Data={this.state.dialogData.customerName}
                    />
                    <Data
                      label={"Father Name"}
                      Data={this.state.dialogData.fatherName}
                    />
                    <Data
                      label={"Date Of Birth"}
                      Data={this.state.dialogData.dateOfBirth}
                    />
                    <Data
                      label={"Mobile Number"}
                      Data={this.state.dialogData.mobileNumber}
                    />
                    <Data
                      label={"Pan Number"}
                      Data={this.state.dialogData.panNumber}
                    />
                    <Data
                      label={"Aadhar Number"}
                      Data={this.state.dialogData.aadharNumber}
                    />
                    <Data
                      label={"Address"}
                      Data={this.state.dialogData.addressLine1}
                    />
                    <Data
                      label={""}
                      Data={this.state.dialogData.addressLine2}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 30 }} />
              <Grid item xs={12} style={{ textAlign: "left", width: "100%" }}>
                <IMultiStepForm dialogData={this.state.dialogData} />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
            </Box>
          </Box>
        );
      }
    };
    console.log(this.props.RegistrationStep1);
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={this.Transition}
          style={{ backgroundColor: "#f7f7f7" }}
        >
          <AppBar style={{ position: "relative", backgroundColor: "#3C4B64" }}>
            <Toolbar>
              <Grid container>
                <Grid item xs={4} style={{ textAlign: "left" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.handleClose();
                      this.reload();
                      setStep(1);
                      setDocument1("");
                      setDocument2("");
                      setDocument3("");
                      setDocument4("");
                      setDocument5("");
                      setDocument6("");
                      setDocument7("");
                      setDocument8("");
                      setDocument9("");
                      setDocument10("");
                      setDocument11("");
                      setDocument12("");
                      setDocument13("");
                      setDocument14("");
                      setDocument15("");
                      setDocument16("");
                      setDocument17("");
                      setDocument18("");
                    }}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", paddingTop: 10 }}
                >
                  <Typography variant="h6">Upload Document</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}></Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {box(this.props)}
        </Dialog>
        <Container maxWidth="lg" fixed>
          <MaterialTable
            icons={tableIcons}
            title="Individual Detail Document Upload Form"
            columns={this.state.columns}
            data={this.props.RegistrationStep1}
            options={{
              search: true,
            }}
            actions={[
              {
                icon: () => <AddPhotoAlternateIcon />,

                tooltip: "Add Document",
                onClick: (event, rowData) => {
                  this.handleClickOpen();
                  this.setState({ dialogData: { ...rowData } });
                  console.log(rowData);
                },
              },
            ]}
          />
        </Container>
      </div>
    );
  }
}

IDUFTable.contextType = mainContext;

const mapStateToProps = (state) => {
  return {
    RegistrationStep1: state.RegistrationStep1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRegistrationStep1: (FormType, onSuccess, onError) =>
      dispatch(loadRegistrationStep1(FormType, onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IDUFTable);
