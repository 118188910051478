import React from "react";
import { compose } from "redux";
import CompanyRegistrationForm from "../../../Forms/Accounts/CompanyRegistrationForm";
import * as ROLES from "../../../UsersComponents/constants/roles";
import { withFirebase } from "../../../UsersComponents/Firebase";
import { withAuthorization } from "../../../UsersComponents/Session";

const CDRFFragment = () => {
  return (
    <div>
      <CompanyRegistrationForm />
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ACCOUNT];
export default compose(
  withAuthorization(condition),
  withFirebase
)(CDRFFragment);
