import React, { useState } from "react";

import { Button } from "@material-ui/core";
import IvarifiedTable from "../../../Tables/Accounts/IVarifiedTable";
import CVarifiedTable from "../../../Tables/Accounts/CVarifiedTable";
import { compose } from "redux";
import { withAuthorization } from "../../../UsersComponents/Session";
import { withFirebase } from "../../../UsersComponents/Firebase";
import * as ROLES from "../../../UsersComponents/constants/roles";

const WFVFAccountsFragment = () => {
  const [FormType, setFormType] = useState(true);

  const Form = () => {
    if (FormType === true) {
      return <IvarifiedTable />;
    } else if (FormType === false) {
      return <CVarifiedTable />;
    }
  };

  console.log(FormType);

  const handleClickOpen = () => {
    setFormType(!FormType);
  };

  return (
    <div>
      <Button
        style={{ padding: 10, margin: 10, marginLeft: 30 }}
        color="primary"
        onClick={handleClickOpen}
      >
        Change Form Type
      </Button>
      {Form()}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ACCOUNT];
export default compose(
  withAuthorization(condition),
  withFirebase
)(WFVFAccountsFragment);
