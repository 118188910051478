import React, { useState } from "react";
import * as ROLES from "../UsersComponents/constants/roles";
import { withAuthorization } from "../UsersComponents/Session";
import { compose } from "redux";
import { withFirebase } from "../UsersComponents/Firebase";
import ICompleteRegistrationTable2 from "../Tables/Users/ICompleteRegistrationTable2";
import CCompleteRegistrationTable2 from "../Tables/Users/CCompleteRegistrationTable2";
import { Button } from "@material-ui/core";

const Details = () => {
  const [FormType, setFormType] = useState(true);

  const Form = () => {
    if (FormType === true) {
      return <ICompleteRegistrationTable2 />;
    } else if (FormType === false) {
      return <CCompleteRegistrationTable2 />;
    }
  };

  console.log(FormType);

  const handleClickOpen = () => {
    setFormType(!FormType);
  };

  return (
    <div>
      <Button
        style={{ padding: 10, margin: 10, marginLeft: 30 }}
        color="primary"
        onClick={handleClickOpen}
      >
        Change Form Type
      </Button>
      {Form()}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.USER];
export default compose(withAuthorization(condition), withFirebase)(Details);
