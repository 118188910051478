import React, { useState } from "react";

import { Button } from "@material-ui/core";
import { compose } from "redux";
import { withFirebase } from "../../../UsersComponents/Firebase";
import { withAuthorization } from "../../../UsersComponents/Session";
import * as ROLES from "../../../UsersComponents/constants/roles";
import ICompleteRegistrationTable1 from "../../../Tables/Admin/DashboardTables/ICompleteRegistrationTable1";
import CCompleteRegistrationTable1 from "../../../Tables/Admin/DashboardTables/CCompleteRegistrationTable1";

const RCDocumentDetailsFragment = () => {
  const [FormType, setFormType] = useState(true);

  const Form = () => {
    if (FormType === true) {
      return <ICompleteRegistrationTable1 />;
    } else if (FormType === false) {
      return <CCompleteRegistrationTable1 />;
    }
  };

  console.log(FormType);

  const handleClickOpen = () => {
    setFormType(!FormType);
  };

  return (
    <div>
      <Button
        style={{ padding: 10, margin: 10, marginLeft: 30 }}
        color="primary"
        onClick={handleClickOpen}
      >
        Change Form Type
      </Button>
      {Form()}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(
  withAuthorization(condition),
  withFirebase
)(RCDocumentDetailsFragment);
