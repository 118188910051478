import React, { useState } from "react";

import { Button } from "@material-ui/core";
import ICompleteRegistrationTable from "../../Tables/Accounts/ICompleteRegistrationTable";
import CCompleteRegistrationTable from "../../Tables/Accounts/CCompleteRegistrationTable";
import { withFirebase } from "../../UsersComponents/Firebase";
import { withAuthorization } from "../../UsersComponents/Session";
import { compose } from "redux";
import * as ROLES from "../../UsersComponents/constants/roles";

const RegistrationDetails = () => {
  const [FormType, setFormType] = useState(true);

  const Form = () => {
    if (FormType === true) {
      return <ICompleteRegistrationTable />;
    } else if (FormType === false) {
      return <CCompleteRegistrationTable />;
    }
  };

  console.log(FormType);

  const handleClickOpen = () => {
    setFormType(!FormType);
  };

  return (
    <div>
      <Button
        style={{ padding: 10, margin: 10, marginLeft: 30 }}
        color="primary"
        onClick={handleClickOpen}
      >
        Change Form Type
      </Button>
      {Form()}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ACCOUNT];
export default compose(
  withAuthorization(condition),
  withFirebase
)(RegistrationDetails);
