import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withFirebase } from "../../UsersComponents/Firebase";
import { withAuthorization } from "../../UsersComponents/Session";
import * as ROLES from "../../UsersComponents/constants/roles";
import HomeCard from "../../Component.js/cards/admin/HomeCard";
import { Grid } from "@material-ui/core";
import { db, firestore } from "../../Firebase";

const Home = () => {
  const [vahicleVal, setVahicleVal] = useState(0);
  const [Form3IRF, setForm3IRF] = useState(0);
  const [Form3CRF, setForm3CRF] = useState(0);
  const [users, setUsers] = useState(0);

  useEffect(() => {
    firestore
      .collection("VahicleRegistration")
      .get()
      .then((querySnapshot) => {
        let VahicleRegistration = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            VahicleRegistration.push(doc.data());
          });
          setVahicleVal(VahicleRegistration.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    let usersData = [];
    db.ref("users").on("value", (snapshot) => {
      usersData = snapshot.numChildren();
      setUsers(usersData);
    });

    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection("IRF")
      .where("Status", "==", "Waiting for Varification")
      .where("validation", "==", true)
      .get()
      .then((querySnapshot) => {
        let RegistrationStep1 = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            RegistrationStep1.push(doc.data());
          });
          setForm3IRF(RegistrationStep1.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection("CRF")
      .where("Status", "==", "Waiting for Varification")
      .where("validation", "==", true)
      .get()
      .then((querySnapshot) => {
        let RegistrationStep1 = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            RegistrationStep1.push(doc.data());
          });
          setForm3CRF(RegistrationStep1.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [vahicleVal]);
  console.log(vahicleVal);

  return (
    <div>
      <Grid container>
        <Grid item sm={3} xs={12} spacing={2}>
          <HomeCard
            title={"Total No. Vehicles Registered"}
            subtitle={"_"}
            value={vahicleVal}
            push={"/Admin/Home/VahicleDetails"}
          />
          <br />
        </Grid>
        <Grid item sm={3} xs={12}>
          <HomeCard
            title={"Form: Complete Documents"}
            subtitle={"Individual Registration Form"}
            value={Form3IRF}
            push={"/Admin/Home/RCDocumentDetails"}
          />
          <br />
        </Grid>
        <Grid item sm={3} xs={12}>
          <HomeCard
            title={"Form: Complete Documents"}
            subtitle={"Company Registration Form"}
            value={Form3CRF}
            push={"/Admin/Home/RCDocumentDetails"}
          />
          <br />
        </Grid>
        <Grid item sm={3} xs={12}>
          <HomeCard
            title={"Registered User"}
            subtitle={"All Users"}
            value={users}
            push={"/Admin/Users"}
          />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(withAuthorization(condition), withFirebase)(Home);
