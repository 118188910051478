import React from "react";
import { compose } from "redux";
import VahicleRegistrationTable from "../../../Tables/Admin/DashboardTables/VahicleRegistrationTable";
import { withFirebase } from "../../../UsersComponents/Firebase";
import { withAuthorization } from "../../../UsersComponents/Session";
import * as ROLES from "../../../UsersComponents/constants/roles";

const VahicleDetailsFragment = () => {
  return (
    <div>
      <VahicleRegistrationTable />
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(
  withAuthorization(condition),
  withFirebase
)(VahicleDetailsFragment);
