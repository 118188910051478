import React from "react";
import { compose } from "redux";
import DistrictTable from "../../../Tables/Admin/RegistrationAreaTable/DistrictTable";
import { withFirebase } from "../../../UsersComponents/Firebase";
import { withAuthorization } from "../../../UsersComponents/Session";
import * as ROLES from "../../../UsersComponents/constants/roles";

const AddDistrictFragment = () => {
  return (
    <div>
      <DistrictTable />
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(
  withAuthorization(condition),
  withFirebase
)(AddDistrictFragment);
