import React from "react";
import { compose } from "redux";
import CCompleteRegistrationTable from "../../../Tables/Accounts/CCompleteRegistrationTable";
import { withFirebase } from "../../../UsersComponents/Firebase";
import { withAuthorization } from "../../../UsersComponents/Session";
import * as ROLES from "../../../UsersComponents/constants/roles";

const DealerDocumentDetailsFragment = () => {
  return (
    <div>
      <CCompleteRegistrationTable />
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(
  withAuthorization(condition),
  withFirebase
)(DealerDocumentDetailsFragment);
