import React from "react";
import { withFirebase } from "../../UsersComponents/Firebase";
import { withAuthorization } from "../../UsersComponents/Session";
import { compose } from "redux";
import * as ROLES from "../../UsersComponents/constants/roles";
import AddAdminTableFragment from "../../Tables/Admin/UserTables/AddAdminTableFragment";

const Settings = () => {
  return (
    <div>
      <AddAdminTableFragment />
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(withAuthorization(condition), withFirebase)(Settings);
