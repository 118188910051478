import React from "react";
import { compose } from "redux";
import IDUFTable from "../../Tables/BackOffice/IDUFTable";
import { withFirebase } from "../../UsersComponents/Firebase";
import { withAuthorization } from "../../UsersComponents/Session";
import * as ROLES from "../../UsersComponents/constants/roles";
import { Grid } from "@material-ui/core";

const IDUFFragment = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <IDUFTable FormType={"IRF"} />
        </Grid>
      </Grid>
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.BACKOFFICE];
export default compose(
  withAuthorization(condition),
  withFirebase
)(IDUFFragment);
