import { combineReducers } from "redux";
import VahicleRegistrationReducer from "./Admin/Dashboard/VahicleRegistrationReducer";
import DistrictsReducer from "./Admin/RegistrationArea/DistrictReducer";
import StateReducer from "./Admin/RegistrationArea/StateReducer";
import RegistrationStep1Reducer from "./RegistrationStep1ActionReducer";
import IndividualUploadReducer from "./BackOffice/IndividualUploadReducer";
import UserReducer from "./Admin/Users/UserReducer";
import BackofficeReducer from "./Admin/Users/BackOfficeReducer";
import AccountReducer from "./Admin/Users/AccountReducer";

const DEFAULT_REDUCER = (initstate, action) => {
  return {
    key: "hello World",
  };
};

const rootReducer = combineReducers({
  DEFAULT: DEFAULT_REDUCER,
  vahicleRegistration: VahicleRegistrationReducer,
  states: StateReducer,
  districts: DistrictsReducer,
  RegistrationStep1: RegistrationStep1Reducer,
  IndividualUpload: IndividualUploadReducer,
  user: UserReducer,
  backoffice: BackofficeReducer,
  account: AccountReducer,
});

export default rootReducer;
