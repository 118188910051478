import React, { useContext, useEffect, useState } from "react";
import { Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { mainContext } from "../../Contexts/MainContext";
import { firestore, storage } from "../../Firebase";
import { connect } from "react-redux";
import {
  addIndividualUpload,
  loadIndividualUpload,
} from "../../Store/Action/BackOffice/IndividualUploadAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper1: {
    width: "100%",
    height: "100%",
    paddingTop: 10,
    paddingRight: 30,
    paddingBottom: 50,
    paddingLeft: 80,
  },
  paper2: {
    height: "100%",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
    backgroundColor: "#B3CDE0",
  },
  control: {
    padding: theme.spacing(2),
  },
  inputLable: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
}));

const Seventhstep2 = (props) => {
  const classes = useStyles();
  const {
    setStep,
    document7,
    setDocument7,
    file7,
    setFile7,
  } = useContext(mainContext);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const FormType = props.dialogData.FileType;
    const Form = props.dialogData.uid;
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document7")
      .get()
      .then((document) => {
        setDocument7(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props, setDocument7]);

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileType = file["type"];
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "application/pdf",
      ];
      if (validImageTypes.includes(fileType)) {
        setFile7(file);
        
      } else {
        console.log("error");
      }
    }
  };

  const onSubmit = () => {
    if (file7 !== "") {
      const form = props.dialogData.uid;
      const Data = [{ url: document7, index: 6, uid: "Document7" }];
      console.log(Data);
      props.addIndividualUpload(
        ...Data,
        props.dialogData.FileType,
        form,
        () => {
          console.log("Success");
        },
        () => {
          //error
          console.log("error");
        }
      );
    }
  };

  const handleUpload = () => {
    if (file7) {
      const uploadTask = storage
        .ref(`Documents/${props.dialogData.uid}/Document7`)
        .put(file7);
      uploadTask.on("state_changed", (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        storage
          .ref(`Documents/${props.dialogData.uid}`)
          .child("Document7")
          .getDownloadURL()
          .then((url) => {
            setDocument7(url);
            setProgress(0);
          });
      });
    }
  };

  const Image = () => {
    if (document7 === "") {
      return (
        <div>
          <h1>Nothing Here</h1>
        </div>
      );
    } else if (document7 !== "") {
      return <p>Document Uploaded</p>;
    }
  };

  const OpenDocument = () => {
    if (document7 !== "") {
      return (
        <table>
          <tbody>
            <tr>
              <td onClick={() => window.open(document7, "_blank")}>
                <p style={{ color: "blue", textAlign: "right" }}>
                  {" "}
                  Open Document{" "}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={2} justify="left">
        <Grid item xs={12}>
          <Grid container justify="left">
            <Grid item xs={12}>
              <Paper className={classes.paper1} elevation={2}>
                <div>
                  <Paper
                    elevation={0}
                    className={classes.paper2}
                    variant="outlined"
                  >
                    RC Document Upload 07 - Fee or Tax receipt or bank challan
                  </Paper>
                  <h4>Upload Document Here</h4>
                  <>
                    <input type="file" onChange={handleDocumentChange} />
                    <Button variant="contained" onClick={handleUpload}>
                      Upload
                    </Button>
                    <div style={{ height: "20px" }}>
                      {progress > 0 ? (
                        <progress value={progress} max="100" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Grid container>
                        <Grid item xs={3}>
                          {Image()}
                        </Grid>
                        <Grid item xs={9}>
                          {OpenDocument()}
                        </Grid>
                      </Grid>
                    </div>
                  </>
                  <div className="radio-button">
                    <Divider style={{ marginBottom: 20 }} />
                    <Grid item xs={12} style={{ verticalAlign: "bottom" }}>
                      <Grid container>
                        <Grid item xs={4} style={{ textAlign: "left" }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => setStep(6)}
                            color="inherit"
                          >
                            Previous
                          </Button>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            size="large"
                            color="inherit"
                            onClick={() => {
                              onSubmit();
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                              setStep(8);
                              onSubmit();
                            }}
                            color="primary"
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IndividualUpload: state.IndividualUpload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadIndividualUpload: (FormType, Form, onSuccess, onError) =>
      dispatch(loadIndividualUpload(FormType, Form, onSuccess, onError)),
    addIndividualUpload: (data, FormType, Form, onSuccess, onError) =>
      dispatch(addIndividualUpload(data, FormType, Form, onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Seventhstep2);
