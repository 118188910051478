import React, { Component, forwardRef } from "react";
import { Container, Grid } from "@material-ui/core";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { connect } from "react-redux";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";
import { loadRegistrationStep1 } from "../../Store/Action/RegistrationStep1Action";
import CUpdateDetailForm from "../../Forms/Accounts/CUpdateDetailForm";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class CPendingDocumentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: "Company's Name", field: "companyName" },
        { title: "Registration Date", field: "dateOfRegistration" },
        { title: "Sale Date", field: "saleDate" },
        { title: "Form Uploaded by", field: "FormUploadedby" },
        { title: "Comment", field: "comment", editable: false },
      ],
      open: false,
      dialogData: [],
      FormType: "CRF",
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  componentDidMount() {
    this.props.loadRegistrationStep1(
      this.state.FormType,
      () => {},
      () => {
        //error
      }
    );
  }

  resetValue() {
    this.props.loadRegistrationStep1(
      this.state.FormType,
      () => {},
      () => {
        //error
      }
    );
  }

  render() {
    console.log(this.props.FormType);

    console.log(this.props.RegistrationStep1);
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={this.Transition}
          style={{ backgroundColor: "#f7f7f7" }}
        >
          <AppBar style={{ position: "relative", backgroundColor: "#3C4B64" }}>
            <Toolbar>
              <Grid container>
                <Grid item xs={4} style={{ textAlign: "left" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.handleClose();
                      this.resetValue();
                    }}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", paddingTop: 10 }}
                >
                  <Typography variant="h6">Upload Document</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}></Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <CUpdateDetailForm dialogData={this.state.dialogData} />
        </Dialog>
        <Container maxWidth="lg" fixed>
          <MaterialTable
            icons={tableIcons}
            title="Pending for Upload Company Document"
            columns={this.state.columns}
            data={this.props.RegistrationStep1}
            options={{
              exportButton: true,
              search: true,
            }}
            actions={[
              {
                icon: () => <AddPhotoAlternateIcon />,

                tooltip: "Add Document",
                onClick: (event, rowData) => {
                  this.handleClickOpen();
                  this.setState({ dialogData: { ...rowData } });
                  console.log(rowData);
                },
              },
            ]}
          />
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    RegistrationStep1: state.RegistrationStep1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRegistrationStep1: (FormType, onSuccess, onError) =>
      dispatch(loadRegistrationStep1(FormType, onSuccess, onError)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CPendingDocumentTable);
