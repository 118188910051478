import React, { useContext, useEffect } from "react";
import { Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { mainContext } from "../../Contexts/MainContext";
import { firestore } from "../../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper1: {
    width: "100%",
    paddingTop: 10,
    paddingRight: 30,
    paddingBottom: 50,
    paddingLeft: 80,
  },
  paper2: {
    height: "100%",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
    backgroundColor: "#B3CDE0",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const Firststep = (props) => {
  const classes = useStyles();
  const {
    setStep,
    document1,
    document2,
    document3,
    document4,
    document5,
    document6,
    document7,
    document8,
    document9,
    document10,
    document11,
    document12,
    document13,
    document14,
    document15,
    document16,
    document17,
    document18,
    setDocument1,
    setDocument2,
    setDocument3,
    setDocument4,
    setDocument5,
    setDocument6,
    setDocument7,
    setDocument8,
    setDocument9,
    setDocument10,
    setDocument11,
    setDocument12,
    setDocument13,
    setDocument14,
    setDocument15,
    setDocument16,
    setDocument17,
    setDocument18,
  } = useContext(mainContext);

  const downloadFile = () => {
    var url = document1;
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = "AadharProof"; // Name the file anything you'd like.
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", url);
    xhr.send();
  };

  useEffect(() => {
    const FormType = props.dialogData.FileType;
    const Form = props.dialogData.uid;
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document1")
      .get()
      .then((document) => {
        setDocument1(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document2")
      .get()
      .then((document) => {
        setDocument2(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document3")
      .get()
      .then((document) => {
        setDocument3(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document4")
      .get()
      .then((document) => {
        setDocument4(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document5")
      .get()
      .then((document) => {
        setDocument5(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document6")
      .get()
      .then((document) => {
        setDocument6(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document7")
      .get()
      .then((document) => {
        setDocument7(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document8")
      .get()
      .then((document) => {
        setDocument8(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document9")
      .get()
      .then((document) => {
        setDocument9(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document10")
      .get()
      .then((document) => {
        setDocument10(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document11")
      .get()
      .then((document) => {
        setDocument11(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document12")
      .get()
      .then((document) => {
        setDocument12(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document13")
      .get()
      .then((document) => {
        setDocument13(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("DealingDocuments")
      .doc("Document1")
      .get()
      .then((document) => {
        setDocument14(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("DealingDocuments")
      .doc("Document2")
      .get()
      .then((document) => {
        setDocument15(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("DealingDocuments")
      .doc("Document3")
      .get()
      .then((document) => {
        setDocument16(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("DealingDocuments")
      .doc("Document4")
      .get()
      .then((document) => {
        setDocument17(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("DealingDocuments")
      .doc("Document5")
      .get()
      .then((document) => {
        setDocument18(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    props,
    setDocument1,
    setDocument2,
    setDocument3,
    setDocument4,
    setDocument5,
    setDocument6,
    setDocument7,
    setDocument8,
    setDocument9,
    setDocument10,
    setDocument11,
    setDocument12,
    setDocument13,
    setDocument14,
    setDocument15,
    setDocument16,
    setDocument17,
    setDocument18,
  ]);

  const Image = () => {
    if (document1 === "") {
      return (
        <div>
          <h3>Nothing Here</h3>
        </div>
      );
    } else if (document1 !== "") {
      return <p>Document Uploaded</p>;
    }
  };

  const OpenDocument = () => {
    if (document1 !== "") {
      return (
        <Grid container>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td onClick={() => window.open(document1, "_blank")}>
                    <p style={{ color: "blue", textAlign: "right" }}>
                      {" "}
                      Open Document{" "}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 5 }}>
            <Button fullWidth onClick={() => downloadFile()}>
              Download
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const DownloadAllFilesHandler1 = (download, fileName) => {
    if (download !== "") {
      var url = download;
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = fileName; // Name the file anything you'd like.
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
      };
      xhr.open("GET", url);
      xhr.send();
    }
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={2} justify="left">
        <Grid item xs={12}>
          <Grid container justify="left">
            <Grid item xs={12}>
              <Paper
                className={classes.paper1}
                elevation={2}
                style={{ paddingTop: 20 }}
              >
                <div>
                  <Paper
                    elevation={0}
                    className={classes.paper2}
                    variant="outlined"
                  >
                    RC Document Upload 01 - Aadhar Proof
                  </Paper>
                  <>
                    <Grid container>
                      <Grid item xs={3}>
                        {Image()}
                      </Grid>
                      <Grid item xs={9}>
                        {OpenDocument()}
                      </Grid>
                    </Grid>
                  </>
                  <div className="radio-button">
                    <Divider style={{ marginBottom: 20 }} />
                    <Grid item xs={12} style={{ verticalAlign: "bottom" }}>
                      <Grid container>
                        <Grid item xs={8} style={{ textAlign: "left" }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                              DownloadAllFilesHandler1(
                                document1,
                                "AadharProof"
                              );
                              DownloadAllFilesHandler1(
                                document2,
                                "ChassisPrint"
                              );
                              DownloadAllFilesHandler1(
                                document3,
                                "DeclarationForm"
                              );
                              DownloadAllFilesHandler1(document4, "Form20");
                              DownloadAllFilesHandler1(document5, "Form21");
                              DownloadAllFilesHandler1(document6, "Form22");
                              DownloadAllFilesHandler1(
                                document7,
                                "FeeorTaxReceiptOrBankChallan"
                              );
                              DownloadAllFilesHandler1(
                                document8,
                                "InsuranceCertificate"
                              );
                              DownloadAllFilesHandler1(
                                document9,
                                "ProvisionalRC_TemporaryNumber"
                              );
                              DownloadAllFilesHandler1(
                                document10,
                                "Selfdeclarationform"
                              );
                              DownloadAllFilesHandler1(
                                document11,
                                "SalesInvoice"
                              );
                              DownloadAllFilesHandler1(
                                document12,
                                "Jumping_or_reserveChoiceFee"
                              );
                              DownloadAllFilesHandler1(document13, "Other");
                              DownloadAllFilesHandler1(
                                document14,
                                "OrderBookingForm"
                              );
                              DownloadAllFilesHandler1(
                                document15,
                                "VINAllocationForm"
                              );
                              DownloadAllFilesHandler1(document16, "DealSheet");
                              DownloadAllFilesHandler1(document17, "DANForm");
                              DownloadAllFilesHandler1(document18, "GatePass");
                            }}
                            color="primary"
                          >
                            Download All Files
                          </Button>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                              setStep(2);
                            }}
                            color="primary"
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Firststep;
