import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "../../Component.js/FormComponents/TextField";
import DateTimePicker from "../../Component.js/FormComponents/DateTimePicker";
import Button from "../../Component.js/FormComponents/Button";
import { firestore } from "../../Firebase";
import StateAutocomplete from "../../Component.js/FormComponents/StateAutocomplete";
import { mainContext } from "../../Contexts/MainContext";
import ViewDistrict from "../../Component.js/FormComponents/ViewDistrict";
import Autocomplete from "../../Component.js/FormComponents/Autocomplete";
import SelectWrapper from "../../Component.js/FormComponents/Select";
import DisTextField from "../../Component.js/FormComponents/DisTextField";

const CUpdateDetailForm = (props) => {
  const { setFormState, vinNumber } = useContext(mainContext);
  const [loading, setLoading] = useState(false);

  let EngineNumber = "";
  let ModelName = "";
  let ModelColor = "";
  let CommisionNumber = "";
  let vahicleOther = "";

  if (vinNumber !== "") {
    EngineNumber = vinNumber.EngineNumber;
    ModelName = vinNumber.ModelName;
    ModelColor = vinNumber.ModelColor;
    CommisionNumber = vinNumber.CommisionNumber;
    vahicleOther = vinNumber.Other;
  }

  const INITIAL_FORM_STATE = {
    companyName: props.dialogData.companyName,
    addressLine1: props.dialogData.addressLine1,
    addressLine2: props.dialogData.addressLine2,
    state: props.dialogData.state,
    district: props.dialogData.district,
    pincode: props.dialogData.pincode,
    contactNumber: props.dialogData.contactNumber,
    panNumber: props.dialogData.panNumber,
    aadharNumber: props.dialogData.aadharNumber,
    dateOfRegistration: props.dialogData.dateOfRegistration,
    timeOfRegistration: props.dialogData.timeOfRegistration,
    saleDate: props.dialogData.saleDate,
    other: props.dialogData.other,
    apName: props.dialogData.apName,
    apNumber: props.dialogData.apNumber,
    apDesignation: props.dialogData.apDesignation,
    EngineNumber: props.dialogData.EngineNumber,
    ModelName: props.dialogData.ModelName,
    ModelColor: props.dialogData.ModelColor,
    CommisionNumber: props.dialogData.CommisionNumber,
    vahicleOther: props.dialogData.vahicleOther,
    VinNumber: props.dialogData.VinNumber,
    type: props.dialogData.type,
  };

  const FORM_VALIDATION = Yup.object().shape({
    companyName: Yup.string()
      .matches(/^[a-z]+$/, "Must be only text")
      .required("Required"),
    addressLine1: Yup.string().required("Required"),
    addressLine2: Yup.string(),
    state: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    pincode: Yup.number()
      .integer()
      .typeError("Please enter a valid pincode")
      .required("Required"),
    contactNumber: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid phone number")
      .required("Required")
      .length(10, "Must be exactly 10 digits"),
    apName: Yup.string().matches(/^[a-z]+$/, "Must be only text"),
    apNumber: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid phone number")
      .length(10, "Must be exactly 10 digits"),
    panNumber: Yup.string()
      .length(10, "Must be exactly 10 digits")
      .required("Required"),
    aadharNumber: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid phone number")
      .required("Required")
      .length(12, "Must be exactly 12 digits"),
    saleDate: Yup.date().required("Required"),
    other: Yup.string(),
    type: Yup.string().required("Required"),
  });

  const VahicleVin = () => {
    return (
      <Grid item xs={12}>
        <Autocomplete name="VinNumber" id="VinNumber" label="VinNumber" />
      </Grid>
    );
  };

  const VahicleData = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField name="VinNumber" label="VinNumber" disabled={true} />
        </Grid>
        <Grid item xs={12}>
          <DisTextField
            name="EngineNumber"
            label="EngineNumber"
            value={EngineNumber}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <DisTextField
            name="ModelName"
            label="ModelName"
            value={ModelName}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <DisTextField
            name="ModelColor"
            label="ModelColor"
            value={ModelColor}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <DisTextField
            name="CommisionNumber"
            label="CommisionNumber"
            value={CommisionNumber}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <DisTextField
            name="vahicleOther"
            label="Other"
            value={vahicleOther}
            disabled={true}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Box p={1} width="100%" bgcolor="white" style={{ padding: 60 }}>
          <Backdrop style={{ zIndex: 500, color: "#ffffff" }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(value, data) => {
              setLoading(true);
              setFormState("");
              data = {
                ...value,
                uid: props.dialogData.uid,
              };
              firestore
                .collection("RegistrationStep1")
                .doc("FormType")
                .collection("CRF")
                .doc(data.uid)
                .update(data)
                .then(function (doc) {
                  console.log(data);
                  setLoading(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Company Registration Form
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Company Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField name="companyName" label="Company Name" />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="contactNumber" label="Contact Number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="panNumber" label="PAN Number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="aadharNumber" label="Aadhar Number" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Address Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="addressLine1" label="Address Line 1" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="addressLine2" label="Address Line 2" />
                </Grid>
                <Grid item xs={12}>
                  <StateAutocomplete name="state" label="State" />
                </Grid>
                <Grid item xs={12}>
                  <ViewDistrict />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="pincode" label="PIN Code" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Authorised Person Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField name="apName" label="Authorised Person Name" />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="apNumber" label="Authorised Person Number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="apDesignation"
                    label="Authorised Person Designation"
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Sales Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="dateOfRegistration"
                    label="Date Of Registration"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker name="saleDate" label="Sale Date" />
                </Grid>

                <Grid item xs={12}>
                  <SelectWrapper
                    name="type"
                    id="type"
                    label="Registration type"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="other" label="Other" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Vahicle Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  {VahicleVin()}
                </Grid>
                <Grid item xs={12}>
                  {VahicleData()}
                </Grid>
                <Grid item xs={12}>
                  <Button>Submit Form</Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default CUpdateDetailForm;
