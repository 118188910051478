import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withFirebase } from "../../UsersComponents/Firebase";
import { withAuthorization } from "../../UsersComponents/Session";
import * as ROLES from "../../UsersComponents/constants/roles";
import HomeCard from "../../Component.js/cards/admin/HomeCard";
import { Grid } from "@material-ui/core";
import { firestore } from "../../Firebase";

const DUF = () => {
  const [Form1IRF, setForm1IRF] = useState(0);
  const [Form1CRF, setForm1CRF] = useState(0);

  useEffect(() => {
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection("IRF")
      .where("Status", "==", "Pending for Upload Documents")
      .get()
      .then((querySnapshot) => {
        let RegistrationStep1 = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            RegistrationStep1.push(doc.data());
          });
          setForm1IRF(RegistrationStep1.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection("CRF")
      .where("Status", "==", "Pending for Upload Documents")
      .get()
      .then((querySnapshot) => {
        let RegistrationStep1 = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            RegistrationStep1.push(doc.data());
          });
          setForm1CRF(RegistrationStep1.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item sm={3} xs={12}>
          <HomeCard
            title={"Form: Pending for upload document"}
            subtitle={"Individual Registration Form"}
            value={Form1IRF}
            push={"/BackOffice/DUF/IDUF"}
          />
          <br />
        </Grid>
        <Grid item sm={3} xs={12}>
          <HomeCard
            title={"Form: Pending for upload document"}
            subtitle={"Company Registration Form"}
            value={Form1CRF}
            push={"/BackOffice/DUF/CDUF"}
          />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.BACKOFFICE];
export default compose(withAuthorization(condition), withFirebase)(DUF);
