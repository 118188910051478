import React, { useContext, useEffect } from "react";
import { Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { mainContext } from "../../Contexts/MainContext";
import { firestore } from "../../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper1: {
    width: "100%",
    height: "100%",
    paddingTop: 10,
    paddingRight: 30,
    paddingBottom: 50,
    paddingLeft: 80,
  },
  paper2: {
    height: "100%",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
    backgroundColor: "#B3CDE0",
  },
  control: {
    padding: theme.spacing(2),
  },
  inputLable: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
}));

const Thirdstep = (props) => {
  const classes = useStyles();
  const { setStep, document3, setDocument3 } = useContext(mainContext);

  const Image = () => {
    if (document3 === "") {
      return (
        <div>
          <h1>Nothing Here</h1>
        </div>
      );
    } else if (document3 !== "") {
      return <p>Document Uploaded</p>;
    }
  };

  useEffect(() => {
    const FormType = props.dialogData.FileType;
    const Form = props.dialogData.uid;
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .doc(Form)
      .collection("Documents")
      .doc("Document3")
      .get()
      .then((document) => {
        setDocument3(document.data().url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props, setDocument3]);

  const downloadFile = () => {
    var url = document3;
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = "DeclarationForm"; // Name the file anything you'd like.
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", url);
    xhr.send();
  };

  const OpenDocument = () => {
    if (document3 !== "") {
      return (
        <Grid container>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td onClick={() => window.open(document3, "_blank")}>
                    <p style={{ color: "blue", textAlign: "right" }}>
                      {" "}
                      Open Document{" "}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 5 }}>
            <Button fullWidth onClick={() => downloadFile()}>
              Download
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={2} justify="left">
        <Grid item xs={12}>
          <Grid container justify="left">
            <Grid item xs={12}>
              <Paper className={classes.paper1} elevation={2}>
                <div>
                  <Paper
                    elevation={0}
                    className={classes.paper2}
                    variant="outlined"
                  >
                    RC Document Upload 03 - Declaration Form
                  </Paper>
                  <>
                    <div>
                      <Grid container>
                        <Grid item xs={3}>
                          {Image()}
                        </Grid>
                        <Grid item xs={9}>
                          {OpenDocument()}
                        </Grid>
                      </Grid>
                    </div>
                  </>
                  <div className="radio-button">
                    <Divider style={{ marginBottom: 20 }} />
                    <Grid item xs={12} style={{ verticalAlign: "bottom" }}>
                      <Grid container>
                        <Grid item xs={4} style={{ textAlign: "left" }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => setStep(2)}
                            color="inherit"
                          >
                            Previous
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ textAlign: "center" }}
                        ></Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                              setStep(4);
                            }}
                            color="primary"
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Thirdstep;
