import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "../../Component.js/FormComponents/TextField";
import DateTimePicker from "../../Component.js/FormComponents/DateTimePicker";
import Button from "../../Component.js/FormComponents/Button";
import { firestore } from "../../Firebase";
import StateAutocomplete from "../../Component.js/FormComponents/StateAutocomplete";
import { mainContext } from "../../Contexts/MainContext";
import ViewDistrict from "../../Component.js/FormComponents/ViewDistrict";
import Autocomplete from "../../Component.js/FormComponents/Autocomplete";
import SelectWrapper from "../../Component.js/FormComponents/Select";
import DisTextField from "../../Component.js/FormComponents/DisTextField";

const IUpdateDetailForm = (props) => {
  const { setFormState, vinNumber } = useContext(mainContext);
  const [loading, setLoading] = useState(false);

  let EngineNumber = "";
  let ModelName = "";
  let ModelColor = "";
  let CommisionNumber = "";
  let vahicleOther = "";

  if (vinNumber !== "") {
    EngineNumber = vinNumber.EngineNumber;
    ModelName = vinNumber.ModelName;
    ModelColor = vinNumber.ModelColor;
    CommisionNumber = vinNumber.CommisionNumber;
    vahicleOther = vinNumber.Other;
  }

  const INITIAL_FORM_STATE = {
    customerName: props.dialogData.customerName,
    fatherName: props.dialogData.fatherName,
    dateOfBirth: props.dialogData.dateOfBirth,
    addressLine1: props.dialogData.addressLine1,
    addressLine2: props.dialogData.addressLine2,
    type: props.dialogData.type,
    state: props.dialogData.state,
    district: props.dialogData.district,
    pincode: props.dialogData.pincode,
    mobileNumber: props.dialogData.mobileNumber,
    panNumber: props.dialogData.panNumber,
    aadharNumber: props.dialogData.aadharNumber,
    dateOfRegistration: props.dialogData.dateOfRegistration,
    timeOfRegistration: props.dialogData.timeOfRegistration,
    saleDate: props.dialogData.saleDate,
    other: props.dialogData.other,
    EngineNumber: props.dialogData.EngineNumber,
    ModelName: props.dialogData.ModelName,
    ModelColor: props.dialogData.ModelColor,
    CommisionNumber: props.dialogData.CommisionNumber,
    vahicleOther: props.dialogData.vahicleOther,
    VinNumber: props.dialogData.VinNumber,
  };

  console.log(props.dialogData);

  const FORM_VALIDATION = Yup.object().shape({
    customerName: Yup.string()
      .matches(/^[a-z]+$/, "Must be only text")
      .required("Required"),
    fatherName: Yup.string().matches(/^[a-z]+$/, "Must be only text"),
    dateOfBirth: Yup.date().required("Required"),
    addressLine1: Yup.string().required("Required"),
    addressLine2: Yup.string(),
    state: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    pincode: Yup.number()
      .typeError("Please enter a valid pincode")
      .required("Required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid phone number")
      .required("Required")
      .length(10, "Must be exactly 10 digits"),
    panNumber: Yup.string()
      .length(10, "Must be exactly 10 digits")
      .required("Required"),
    aadharNumber: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a Aadhar phone number")
      .length(12, "Must be exactly 12 digits"),
    saleDate: Yup.date().required("Required"),
    other: Yup.string(),
    type: Yup.string().required("Required"),
    VinNumber: Yup.string().required("Required"),
  });

  const VahicleVin = () => {
    return (
      <Grid item xs={12}>
        <Autocomplete name="VinNumber" id="VinNumber" label="VinNumber" />
      </Grid>
    );
  };

  const VahicleData = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField name="VinNumber" label="VinNumber" disabled={true} />
        </Grid>
        <Grid item xs={12}>
          <DisTextField
            name="EngineNumber"
            label="EngineNumber"
            value={EngineNumber}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <DisTextField
            name="ModelName"
            label="ModelName"
            value={ModelName}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <DisTextField
            name="ModelColor"
            label="ModelColor"
            value={ModelColor}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <DisTextField
            name="CommisionNumber"
            label="CommisionNumber"
            value={CommisionNumber}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <DisTextField
            name="vahicleOther"
            label="Other"
            value={vahicleOther}
            disabled={true}
          />
        </Grid>
      </Grid>
    );
  };

  const onSubmit = (value, data, resetForm) => {
    try {
      setLoading(true);
      setFormState("");
      data = {
        ...value,
        uid: props.dialogData.uid,
      };
      firestore
        .collection("RegistrationStep1")
        .doc("FormType")
        .collection("IRF")
        .doc(data.uid)
        .update(data)
        .then(function (doc) {
          console.log("Success");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
      resetForm({});
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Box p={1} width="100%" bgcolor="white" style={{ padding: 60 }}>
          <Backdrop style={{ zIndex: 500, color: "#ffffff" }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={onSubmit}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Individual Registration Form
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Personal Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField name="customerName" label="Customer Name" />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="fatherName" label="Father's Name / Husband Name" />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker name="dateOfBirth" label="Date Of Birth" />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="mobileNumber" label="Mobile Number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="panNumber" label="PAN Number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="aadharNumber" label="Aadhar Number" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Address Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField name="addressLine1" label="Address Line 1" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="addressLine2" label="Address Line 2" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Select to change state:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <StateAutocomplete name="state" id="State" label="State" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="state" label="State" disabled={true} />
                </Grid>
                <Grid item xs={12}>
                  <ViewDistrict />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="pincode" label="PIN Code" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Sales Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="dateOfRegistration"
                    label="Date Of Registration"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker name="saleDate" label="Sale Date" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Select to Registration type:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="type"
                    id="type"
                    label="Registration type"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="type" label="Registration type" />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="other" label="Other" />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Vahicle Details</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 40 }}>
                  <Typography>Select to VIN Number:</Typography>
                </Grid>
                <Grid item xs={12}>
                  {VahicleVin()}
                </Grid>
                <Grid item xs={12}>
                  {VahicleData()}
                </Grid>
                <Grid item xs={12}>
                  <Button>Submit Form</Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default IUpdateDetailForm;
