import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Navigation from "./Component.js/Navigation";
import Home from "./Pages/Admin/Home";
import RegistrationArea from "./Pages/Admin/RegistrationArea";
import Settings from "./Pages/Admin/Settings";
import User from "./Pages/Admin/User";
import VahicleDetailsFragment from "./Fragments/AdminFragments/DashboardFragment/VahicleDetailsFragment";
import DealerDocumentDetailsFragment from "./Fragments/AdminFragments/DashboardFragment/DealerDocumentDetailsFragment";
import RCDocumentDetailsFragment from "./Fragments/AdminFragments/DashboardFragment/RCDocumentDetailsFragment";
import AddDistrictFragment from "./Fragments/AdminFragments/RegistrationAreaFragments/AddDistrictFragment";
import BlockedUsersFragment from "./Fragments/AdminFragments/SettingsFragments/BlockedUsersFragment";
import CreateUsersFragment from "./Fragments/AdminFragments/SettingsFragments/CreateUsersFragment";
import AccountsFragment from "./Fragments/AdminFragments/UserFragments/AccountsFragment";
import UserFragment from "./Fragments/AdminFragments/UserFragments/UserFragment";
import BackOfficeFragment from "./Fragments/AdminFragments/UserFragments/BackOfficeFragment";

import DFR from "./Pages/Accounts/DFR";
import RegistrationDetails from "./Pages/Accounts/RegistrationDetails";
import CDRFFragment from "./Fragments/AccountsFragments/HomeFragments/CDRFFragment";
import IDFRFragment from "./Fragments/AccountsFragments/HomeFragments/IDFRFragment";
import PFUDAccountsFragment from "./Fragments/AccountsFragments/RegistrationDetailsFragments/PFUDAccountsFragment";
import WFVFAccountsFragment from "./Fragments/AccountsFragments/RegistrationDetailsFragments/WFVFAccountsragment";

import DUF from "./Pages/BackOffice/DUF";
import CDUFFragment from "./Fragments/BackOfficeFragments/CDUFFragment";
import IDUFFragment from "./Fragments/BackOfficeFragments/IDUFFragment";

import ErrorPage from "./Pages/404page";
import Details from "./Pages/Details";
import Login from "./Pages/Login";
import { withAuthentication } from "./UsersComponents/Session/index";
import PasswordForgetPage from "./Pages/ForgotPassword";
import AdminFragment from "./Fragments/AdminFragments/UserFragments/AdminFragment";

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/Admin">
            <Redirect to="/Admin/Home" />
          </Route>

          <Route exact path="/BackOffice">
            <Redirect to="/BackOffice/DUF" />
          </Route>

          <Route exact path="/Accounts">
            <Redirect to="/Accounts/DRF" />
          </Route>

          <Route exact path="/login" component={Login} />

          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/Forgetpassword" component={PasswordForgetPage} />

          <Navigation>
            {/*AdminRoutes*/}

            <Route exact path="/Admin/Home" component={Home} />
            <Route exact path="/Admin/users" component={User} />
            <Route exact path="/Admin/settings" component={Settings} />
            <Route
              exact
              path="/Admin/registrationarea"
              component={RegistrationArea}
            />

            {/* Admin routes for Admin Fragment */}

            <Route
              exact
              path="/Admin/Home/Vahicledetails"
              component={VahicleDetailsFragment}
            />
            <Route
              exact
              path="/Admin/Home/rcdocumentdetails"
              component={RCDocumentDetailsFragment}
            />
            <Route
              exact
              path="/Admin/Home/dealerdocumentdetails"
              component={DealerDocumentDetailsFragment}
            />
            {/* Admin routes for User Fragment */}

            <Route
              exact
              path="/Admin/Users/BackOffice"
              component={BackOfficeFragment}
            />
            <Route exact path="/Admin/users/User" component={UserFragment} />
            <Route
              exact
              path="/Admin/users/Accounts"
              component={AccountsFragment}
            />
            <Route
              exact
              path="/Admin/users/Admin"
              component={AdminFragment}
            />

            {/* Admin routes for settings Fragment */}

            <Route
              exact
              path="/Admin/settings/Allusers"
              component={User}
            />

            <Route
              exact
              path="/Admin/settings/createusers"
              component={CreateUsersFragment}
            />
            <Route
              exact
              path="/Admin/settings/blockedusers"
              component={BlockedUsersFragment}
            />

            {/* Admin routes for registrationarea Fragment */}

            <Route
              exact
              path="/Admin/registrationarea/District"
              component={AddDistrictFragment}
            />

            {/*AccountRoutes*/}

            <Route exact path="/Accounts/DRF" component={DFR} />
            <Route
              exact
              path="/Accounts/RegistrationDetails"
              component={RegistrationDetails}
            />

            {/* Admin routes for Form Fragment */}

            <Route exact path="/Accounts/DRF/IDRF" component={IDFRFragment} />
            <Route exact path="/Accounts/DRF/CDRF" component={CDRFFragment} />

            {/* Admin routes for Registration Fragment */}

            <Route
              exact
              path="/Accounts/RegistrationDetails/PFUDAccounts"
              component={PFUDAccountsFragment}
            />
            <Route
              exact
              path="/Accounts/RegistrationDetails/WFVAccounts"
              component={WFVFAccountsFragment}
            />

            {/*BackOfficeRoutes*/}
            <Route exact path="/BackOffice/DUF" component={DUF} />

            {/* BackOfficeRoutes routes for Form Fragment */}

            <Route exact path="/BackOffice/DUF/IDUF" component={IDUFFragment} />
            <Route exact path="/BackOffice/DUF/CDUF" component={CDUFFragment} />

            {/*URoutes*/}

            <Route exact path="/Users/Details" component={Details} />

            {/*NonAuthRoutes*/}

            <Route exact path="/404" component={ErrorPage} />
          </Navigation>
        </Switch>
      </div>
    </Router>
  );
};

export default withAuthentication(App);
