import React from "react";
import { compose } from "redux";
import CDUFTable from "../../Tables/BackOffice/CDUFTable";
import { withFirebase } from "../../UsersComponents/Firebase";
import { withAuthorization } from "../../UsersComponents/Session";
import * as ROLES from "../../UsersComponents/constants/roles";

const CDUFFragment = () => {
  return (
    <div>
      <CDUFTable FormType={"CRF"} />
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.BACKOFFICE];
export default compose(
  withAuthorization(condition),
  withFirebase
)(CDUFFragment);
