import React, { createContext, useState } from "react";
import App from "../App";
import ErorrDrawers from "../Component.js/Drawers/ErrorDrawer";
import HomeDrawers from "../Component.js/Drawers/AdminDrawers/HomeDrawers";
import RegistrationAreaDrawer from "../Component.js/Drawers/AdminDrawers/RegistrationAreaDrawer";
import SettingDrawers from "../Component.js/Drawers/AdminDrawers/SettingDrawers";
import UsersDrawers from "../Component.js/Drawers/AdminDrawers/UsersDrawers";
import AdminNavbar from "../Component.js/NavBars/AdminNavbar";
import AccountsNavbar from "../Component.js/NavBars/AccountsNavbar";
import DRFDrawer from "../Component.js/Drawers/AccountsDrawers/DRFDrawer";
import RegistrationDetailsDrawer from "../Component.js/Drawers/AccountsDrawers/RegistrationDetailsDrawer";
import BackOfficeNavbar from "../Component.js/NavBars/BackOfficeNavbar";
import DUFDrawer from "../Component.js/Drawers/BackOffice/DUFDrawer";
import RegistrationDetailsBackOfficeDrawer from "../Component.js/Drawers/BackOffice/RegistrationDetailsBackOfficeDrawer";
import { auth, firestore } from "../Firebase";
import UserNavbar from "../Component.js/NavBars/UserNavbar";
import UserDrawer from "../Component.js/Drawers/UserDrawer";
import DUFDrawerMobile from "../Component.js/MobileDrawer/BackOffice/DUFDrawerMobile";
import HomeDrawersMobile from "../Component.js/MobileDrawer/AdminDrawers/HomeDrawersMobile";
import RegistrationAreaDrawerMobile from "../Component.js/MobileDrawer/AdminDrawers/RegistrationAreaDrawerMobile";
import SettingDrawersMobile from "../Component.js/MobileDrawer/AdminDrawers/SettingDrawersMobile";
import UsersDrawersMobile from "../Component.js/MobileDrawer/AdminDrawers/UsersDrawersMobile";
import DRFDrawerMobile from "../Component.js/MobileDrawer/AccountsDrawers/DRFDrawer";
import RegistrationDetailsDrawerMobile from "../Component.js/MobileDrawer/AccountsDrawers/RegistrationDetailsDrawer";
import UserMobileDrawer from "../Component.js/MobileDrawer/UserMobileDrawer";

export const mainContext = createContext();
const Context = () => {
  const [open, setOpen] = useState(true);
  const [state, setState] = useState(false);
  const [userstate, setUserstate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formState, setFormState] = useState("");
  const [vinNumber, SetVinNumber] = useState("");
  const [currentStep, setStep] = useState(1);
  const [userData, setUserData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [loginType, setLoginType] = useState("IsEditor");
  const [loggedIn, setloggedIn] = useState(null);
  const [step, setStep1] = useState(2);

  const [document1, setDocument1] = useState("");
  const [file1, setFile1] = useState("");
  const [document2, setDocument2] = useState("");
  const [file2, setFile2] = useState("");
  const [document3, setDocument3] = useState("");
  const [file3, setFile3] = useState("");
  const [document4, setDocument4] = useState("");
  const [file4, setFile4] = useState("");
  const [document5, setDocument5] = useState("");
  const [file5, setFile5] = useState("");
  const [document6, setDocument6] = useState("");
  const [file6, setFile6] = useState("");
  const [document7, setDocument7] = useState("");
  const [file7, setFile7] = useState("");
  const [document8, setDocument8] = useState("");
  const [file8, setFile8] = useState("");
  const [document9, setDocument9] = useState("");
  const [file9, setFile9] = useState("");
  const [document10, setDocument10] = useState("");
  const [file10, setFile10] = useState("");
  const [document11, setDocument11] = useState("");
  const [file11, setFile11] = useState("");
  const [document12, setDocument12] = useState("");
  const [file12, setFile12] = useState("");
  const [document13, setDocument13] = useState("");
  const [file13, setFile13] = useState("");
  const [document14, setDocument14] = useState("");
  const [file14, setFile14] = useState("");
  const [document15, setDocument15] = useState("");
  const [file15, setFile15] = useState("");
  const [document16, setDocument16] = useState("");
  const [file16, setFile16] = useState("");
  const [document17, setDocument17] = useState("");
  const [file17, setFile17] = useState("");
  const [document18, setDocument18] = useState("");
  const [file18, setFile18] = useState("");
  const [allfile, setAllFile] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [vdata, setVdata] = useState(true);

  function submitData(newRegistrationRef, data) {
    newRegistrationRef = firestore.collection("FirstRegistration").doc();
    data = { ...userData, uid: newRegistrationRef.id, Status: "In Process" };
    console.log(data);
    firestore.collection("FirstRegistration").doc(data.uid).set(data);
    setUserData([""]);
    setStep(1);
    setOpen(false);
  }

  const Logout = () => {
    auth.signOut().catch((err) => {
      console.log(err);
    });
    setloggedIn(null);
    setState(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const toggleUserDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setUserstate(open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePopupClose = () => {
    setOpenPopUp(false);
  };

  const handlePopupOpen = () => {
    setOpenPopUp(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const checkPage = (props) => {
    const {
      location: { pathname },
    } = props;

    const pathnames = pathname.split("/").filter((x) => x);
    const select = `${pathnames[1]}`;
    if (select === "Users") {
      return <UsersDrawers />;
    } else if (select === "Home") {
      return <HomeDrawers />;
    } else if (select === "Settings") {
      return <SettingDrawers />;
    } else if (select === "RegistrationArea") {
      return <RegistrationAreaDrawer />;
    } else if (select === "DRF") {
      return <DRFDrawer />;
    } else if (select === "RegistrationDetails") {
      return <RegistrationDetailsDrawer />;
    } else if (select === "DUF") {
      return <DUFDrawer />;
    } else if (select === "RegistrationDetailsBackOffice") {
      return <RegistrationDetailsBackOfficeDrawer />;
    } else if (select === "Details") {
      return <UserDrawer />;
    } else if (select === "undefined") {
      return <ErorrDrawers />;
    } else {
      return <ErorrDrawers />;
    }
  };

  const checkPageMobile = (props) => {
    const {
      location: { pathname },
    } = props;

    const pathnames = pathname.split("/").filter((x) => x);
    const select = `${pathnames[1]}`;
    if (select === "Users") {
      return <UsersDrawersMobile />;
    } else if (select === "Home") {
      return <HomeDrawersMobile />;
    } else if (select === "Settings") {
      return <SettingDrawersMobile />;
    } else if (select === "RegistrationArea") {
      return <RegistrationAreaDrawerMobile />;
    } else if (select === "DRF") {
      return <DRFDrawerMobile />;
    } else if (select === "RegistrationDetails") {
      return <RegistrationDetailsDrawerMobile />;
    } else if (select === "DUF") {
      return <DUFDrawerMobile />;
    } else if (select === "Details") {
      return <UserMobileDrawer />;
    } else if (select === "undefined") {
      return <ErorrDrawers />;
    } else {
      return <ErorrDrawers />;
    }
  };

  const checkNavbar = (props) => {
    const {
      location: { pathname },
    } = props;

    const pathnames = pathname.split("/").filter((x) => x);
    const select = `${pathnames[0]}`;
    if (select === "Admin") {
      return <AdminNavbar />;
    } else if (select === "Accounts") {
      return <AccountsNavbar />;
    } else if (select === "BackOffice") {
      return <BackOfficeNavbar />;
    } else if (select === "Users") {
      return <UserNavbar />;
    } else if (select === "undefined") {
      return <ErorrDrawers />;
    } else {
      return <ErorrDrawers />;
    }
  };

  return (
    <div>
      <mainContext.Provider
        value={{
          toggleDrawer,
          open,
          setOpen,
          state,
          setState,
          checkPage,
          handleDrawerClose,
          Logout,
          anchorEl,
          setAnchorEl,
          handleClick,
          handleClose,
          userstate,
          toggleUserDrawer,
          checkNavbar,
          formState,
          setFormState,
          handleClickOpen,
          handleClickClose,
          vinNumber,
          SetVinNumber,
          currentStep,
          setStep,
          userData,
          setUserData,
          finalData,
          setFinalData,
          submitData,
          loginType,
          setLoginType,
          loggedIn,
          setloggedIn,
          document1,
          setDocument1,
          file1,
          setFile1,
          document2,
          setDocument2,
          file2,
          setFile2,
          document3,
          setDocument3,
          file3,
          setFile3,
          document4,
          setDocument4,
          file4,
          setFile4,
          document5,
          setDocument5,
          file5,
          setFile5,
          document6,
          setDocument6,
          file6,
          setFile6,
          document7,
          setDocument7,
          file7,
          setFile7,
          document8,
          setDocument8,
          file8,
          setFile8,
          document9,
          setDocument9,
          file9,
          setFile9,
          document10,
          setDocument10,
          file10,
          setFile10,
          file11,
          setFile11,
          file12,
          setFile12,
          file13,
          setFile13,
          document11,
          setDocument11,
          document12,
          setDocument12,
          document13,
          setDocument13,
          step,
          setStep1,
          openPopUp,
          setOpenPopUp,
          handlePopupClose,
          handlePopupOpen,
          vdata,
          setVdata,
          document14,
          setDocument14,
          file14,
          setFile14,
          document15,
          setDocument15,
          file15,
          setFile15,
          document16,
          setDocument16,
          file16,
          setFile16,
          document17,
          setDocument17,
          file17,
          setFile17,
          document18,
          setDocument18,
          file18,
          setFile18,
          allfile,
          setAllFile,
          checkPageMobile,
        }}
      >
        <App />
      </mainContext.Provider>
    </div>
  );
};

export default Context;
