import { firestore } from "../../Firebase";

export const loadRegistrationStep3 = (FormType, onSuccess, onError) => {
  return (dispatch) => {
    firestore
      .collection("RegistrationStep1")
      .doc("FormType")
      .collection(FormType)
      .where("Status", "==", "Waiting for Varification")
      .where("validation", "==", true)
      .get()
      .then((querySnapshot) => {
        let RegistrationStep1 = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            RegistrationStep1.push(doc.data());
          });
          dispatch({
            type: "LOAD_REGISTRATIONSTEP1",
            payload: RegistrationStep1,
          });
          onSuccess();
        } else if (querySnapshot.empty) {
          let RegistrationStep1 = [];
          dispatch({
            type: "LOAD_REGISTRATIONSTEP1",
            payload: RegistrationStep1,
          });
          onSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
        onError();
      });
  };
};
